.Main{
    background: url("../images/bg.jpg");
    height: 50vh;
    padding: 0 5%;
    background-size: cover;
    background-position: top;
    display: flex;
    align-items: center;
    gap: 1%;
    flex-wrap: wrap;
    position: relative;
}
.Main h1{
    flex: 100%;
    text-align: center;
    text-shadow: 2px 2px wheat;
    color: var(--blue);
    font-weight: 900;
    font-family: "Courgette";
    padding-top: 20px;
    padding-bottom: -20px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 2rem;
    display: none;
}
.Main img{
    width: 100%;
    height: 40vh;
    object-fit: cover;
    border-radius: 10px;
}

.Main .div1{
    width: 48%;
}
.Main .div2{
    width: 20%;
}
.Main .div3{
    width: 30%;
}
.Main .div3 h2{
    font-size: 1.5rem;
}
.Main .right{
    background: rgba(0,0,0,0.5);
    padding: 30px;
    border-radius: 20px 0 20px 0;
    color: white;
}
@media only screen and (max-width: 998px){
    .Main{
        height: auto;
        gap: 2%;
        padding: 5% 5%;
    }
    .Main img{
        width: 100%;
        height: 20vh;
        object-fit: cover;
        border-radius: 10px;
    }
    .Main .div1 img{
        object-fit:cover;
        object-position: center;
    }
    .Main .div1{
        width: 100%;
        order: 3;
    }
    .Main .div2{
        width: 40%;
        order: 1;
    }
    .Main .div3{
        width: 50%;
        margin-top: 20px;
        margin-bottom: 20px;
        order: 2;
        flex-grow: 1;
    }
    .Main .right{
        background: rgba(0,0,0,0.5);
        padding: 30px 20px;
        border-radius: 10px;
        color: white;
    }
    .Main .div3 p{
        display: none;
    }
    .Main .div3 h2{
        font-size: 0.875rem;
    }
    .Main .div3 a{
        padding: 5px 10px;
    }
    .Main h1{
        font-size: 1.5rem;
    }
}