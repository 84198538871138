.registration{
    width: 100%;
    margin: auto;
}
.registration h1{
    text-align: center;
    padding: 10px;
    font-size: 1.8rem;
    font-family: 'Times New Roman';
}
.registration span{
    color: white;
    background-color: #10598a;
    border: none;
    padding: 0;
    padding: 5px;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
}
.div div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1%;
    justify-content: center;
    align-items: center;
}
.registration button:focus{
    outline: none;
}
.registration input[type="password"],
.registration input[type="text"],
.registration input[type="email"],
.registration input[type="date"],
select{
    background-color: transparent;
    background: none;
    display: block;
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 5px;
    border: none;
    border: 1px solid rgb(209, 209, 209);
    margin-bottom: 0.5rem;
    box-sizing: border-box;
}
.registration input[type="text"]:focus,
.registration input[type="password"]:focus,
.registration input[type="email"]:focus,
.registration input[type="date"]:focus,
select:focus{
    outline: none;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: grey;
}
::-ms-input-placeholder { /* Microsoft Edge */
    color: grey;
}
.sideNavigation img{
    width: 120px;
    height: 120px;
    border-radius: 100%;
    object-fit: cover;
} 
.sideNavigation figcaption{
    text-align: center;
    font-weight: 700;
    color: rgb(170, 168, 168);
}
.userInfo{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid rgb(205, 202, 202);
    padding: 10px;
    border-radius: 10px;
}
.userInfo .div{
    flex-basis: calc(30% - 10px);
    flex-grow: 1;
    margin: 5px;
    position: relative;
}
.userInfo .submit:last-child{
    flex-basis: calc(100% - 0px);
    display: flex;
    justify-content: center;
    align-items: center;

}
.userInfo .submit button{
    color: white;
    background-color: #10598a;
    border: none;
    padding: 0;
    padding: 6px;
    font-size: 0.9rem;
    width: 32%;
    border-radius: 5px;
}
.registration label{
    font-size: 1rem;
}
canvas{
    border: 1px solid rgb(209, 209, 209);
    width: 80%;
    height: 90px;
    margin-bottom: 10px;
    cursor: pointer;
    background-image: url("../images/default.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
section{
    padding: 10px 20px;
    background: #06363d;
    color: white;
}
.accountMenu{
    border: 1px solid rgb(209, 209, 209);
    padding: 5px 20px;
    grid-column: 1 / 3;
    width: auto;
    border-radius: 5px;
    display: none;
    width: 40%;
}
.no_notifications{
    width: 60%;
    height: auto;
    margin: auto;
    text-align: center;
    padding: 20px 0;
    color: white;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #06363d;
}
.no_notifications i{
    font-size: 3rem;
}
.no_notifications h4{
    font-size: 1.5rem;
    padding: 20px 0;
}
@media only screen and (max-width: 998px){
    .userInfo .div{
        flex-basis: calc(100% - 10px);
        flex-grow: 1;
        margin: 5px;
        position: relative;
    }
}