#images{
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    width: 100%;  
    gap: 10px;; 
}
.images{
    position: relative;
    flex-basis: calc(15% - 20px);
    height: 170px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.over{
    position: absolute;
    top: 20%;
    bottom: 0;
    right: 0;
    left: 0;
    /*background-color: rgba(10, 10, 10, 0.5);*/
    background: linear-gradient(to bottom,transparent, black);
    border-radius: 0 0 10px 10px;
}
.over2{
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 1;
    text-align: left;
    z-index: 1;
}
#images p{
    font-weight: bolder;
}
#images li{
    list-style: none;
}
.images img{
    width: 100%;
    height: inherit;
    border-radius: 10px;
    border: 1px solid rgb(214, 212, 212);
}
.heading{
    border: 1px solid black;
    display: inline-block;
    text-align: center;
    padding: 20px;
    margin-top: 20px;
}
.count{
    top: 5%;
    left: 5%;
    z-index: 1;
    color: white;
    text-shadow: 1px 1px var(--blue);
}
@media only screen and (max-width: 998px){
    #images{
        overflow: auto;
        justify-content: flex-start;
        padding: 0 5%;
    }
    .images{
        flex-basis: calc(39% - 20px);
        flex-shrink: 0;
        height: 100px;
    }
    .over2{
        position: absolute;
        bottom: 0;
        left: 5px;
        z-index: 1;
        text-align: left;
        font-size: 0.875rem;
    }
    .heading{
        padding: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}