.login-container{
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Ensure full viewport height */
}
.login-container .form{
    width: 30%;
}
.login-container button{
    background-color: var(--blue);
    padding: 10px 10px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    width: 100%;
}
.login-container button:hover{
    border-bottom: 2px solid red;
}
.div-new{
    border: 1px solid rgb(211, 211, 211);
    border-radius: 10px;
    margin: 10px 0;
    display: flex;
    align-items: center;
}
.div-new p,
.div-new a{
    font-size: 0.875rem;
}
.links a{
    color: rgb(99, 99, 99);
    font-size: 0.875rem;
}
@media only screen and (max-width: 998px){
    .login-container .form{
        width: 80%;
    }
}