.registration input[type="password"],
.registration input[type="text"],
.registration input[type="email"],
.registration input[type="date"],
.registration select{
    background-color: transparent;
    background: none;
    display: block;
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 5px;
    border: none;
    border: 1px solid rgb(209, 209, 209);
    margin-bottom: 0.5rem;
    box-sizing: border-box;
}
.registration input[type="text"]:focus,
.registration input[type="password"]:focus,
.registration input[type="email"]:focus,
.registration input[type="date"]:focus,
select:focus{
    outline: none;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: grey;
}
::-ms-input-placeholder { /* Microsoft Edge */
    color: grey;
}
.registration canvas{
    border: 1px solid rgb(209, 209, 209);
    width: 30%;
    height: 140px;
    margin-bottom: 10px;
    cursor: pointer;
    background-image: url("../images/default.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.btns{
    color: white;
    background-color: var(--buttons);
    border: none;
    padding: 0;
    padding: 10px;
    font-size: 0.9rem;
    border-radius: 5px;
    cursor: pointer;
}
.btns:hover{
    background-color: #0b3b5b;
}
.registration label{
    font-size: 1rem;
}