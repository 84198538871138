.Header{
    background: whitesmoke;
    position: relative;
    position: sticky;
    top: 0;
    z-index: 2;
}
.search-bar button{
    background: var(--blue);
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
}
.search-bar{
    width: 40%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    background: transparent;
    z-index: 1;
}
.search-bar-click{
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px 5%;
    margin: auto;
    background-color: rgb(229, 228, 228);
}
.Inner{
    padding: 10px 5%;
    background: whitesmoke;
}
.action{
    cursor: pointer;
}
.Navbar{
    background: var(--blue);
    padding-right: 5%;
    justify-content: center;
    display: flex;
}
.Navbar a{
    color: white;
    font-weight: 500;
    padding: 10px;
    transition: 0.4s;
    border-bottom: 2px solid var(--blue);
}
.Navbar a:last-child{
    padding-right: 0;
}
.Navbar a:hover{
    background: var(--grey);
    border-bottom: 2px solid white;
}
.search{
    display: none;
}
.logo img{
    width: 15%;
}
.mobileOn{
    display: none;
}
@media only screen and (max-width: 998px){
    .search{
        display: flex;
    }
    .search-bar{
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        background-color: aliceblue;
        padding: 20px;
    }
    .Navbar{
        /*display: none;*/
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width:  60%;
        padding-right: 0;
    }
    .mobile-menu {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        display: none;
        /* Add styles like positioning, background color, etc. */
    }
      
    .mobile-menu.visible {
        opacity: 1;
        display: block;
    }
    .Navbar a{
        display: block;
        /*
        width: auto;
        flex-shrink: 0;
        padding: 15px;
        */
    }
    .logo h3{
        display: none;
    }
    .logo img{
        width: 40%;
    }
    .mobileOn{
        display: block;
    }
    .fundraise{
        display: none;
    }
}