.Stories{
    padding: 20px 15%;
    margin: auto;
    display: flex;
    text-align: left;
    justify-content: space-evenly;
    align-items: center;
}
.Stories div{
    flex-basis: Calc(50% - 30px);
    padding: 15px;
}
.Stories div img{
    width: 100%;
    height: 250px;
    object-fit: contain;
    object-position: top;
    border-radius: 5px;
    cursor: pointer;
}
.Stories h4{
    font-weight: 700;
    color: orangered;
}
.Stories h6{
    font-weight: 800;
    color: brown;
    color: white;
}
.Stories p{
    color: green;
    color: white;
    /*color: orangered;*/
    text-align: justify;
}
.Stories a{
    border: 1px solid white;
    background: transparent;
    color: white;
    padding: 10px 20px;
    font-weight: 700;
    border-radius: 5px;
    transition: 1s;
}
.Stories a:hover{
    background: orangered;
}
@media only screen and (max-width: 998px){
    .Stories{
        padding: 20px 5%;
        margin: auto;
        display: block;
    }
    .Stories div img{
        height: auto;
    }
}