.mainImage img{
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}
.coverImage img{
    object-fit: contain;
}
.recentDonations{
    border: 1px solid rgb(224, 224, 224);
    padding: 10px;
    border-radius: 5px;
    font-size: 0.875rem;
    color: grey;
}
.otherImages{
    cursor: pointer;
    width: 25%;
}
.activeImage img{
    border: 2px solid red;
}
.otherImages img:hover{
    border: 2px solid red;
}
.detailed{
    padding: 2.5%;
}
.detailed button{
    background: var(--grey);
    color: white;
    border-radius: 10px 0;
    padding: 10px 50px;
}
.detailed button:hover{
    background: #a42713;
    color: white;
}
.btn-group a{
    border-radius: 10px 0;
    padding: 10px 50px;
}
.popup {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity 1s ease-in-out;
    z-index: 2;
}
  
.popup.active {
    display: block;
    opacity: 1;
}

.popup-content{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.popup-content-inner{
    max-height: 70vh;
    overflow: auto;
}
.popup-content h1{
    background: var(--blue);
    padding: 15px 5%;
    color: white;
}
.fa-xmark{
    margin-right: 10px;
}
.fa-xmark:hover{
    color: red;
    font-weight: 900;
    cursor: pointer;
}
.alert{
    background: whitesmoke;
    border-radius: 10px;
    text-align: center;
    box-shadow: 1px 9px 11px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 1px 9px 11px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 9px 11px 0px rgba(0,0,0,0.75);
    position: fixed;
    width: 280px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 3% 2.5%;
    z-index: 999;
}
.sticky {
    position: fixed;
    top: 5%;
    left: 0;
    right: 0;
    z-index: 1;
    width: 90%;
    margin: auto;
}
@media only screen and (max-width: 998px){
    .popup-content{
        width: 90%;
        z-index: 2;
    }
    .btn-group{
        display: flex;
    }
    .btn-group a{
        border-radius: 10px 0;
        padding: 10px 10px;
    }
    .detailed button{
        background: var(--grey);
        color: white;
        border-radius: 10px 0;
        padding: 10px 10px;
    }
    .organiser-benefitiary{
        flex-wrap: wrap;
        gap: 2%;
    }
    .organiser-benefitiary div{
        width: 48%;
        flex-grow: 1;
        flex-shrink: 0;
    }
    .organiser-benefitiary div:last-child{
        margin-bottom: 15px;
    }
}
  