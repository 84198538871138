
.startDiv{
    background: url("../images/fundraising_bg.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding: 2% 0;
}
.startDiv::after{
    position: absolute;
    content: " ";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
}
#divided{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1%;
}
.forms{
    display: inline-block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin: 7px 0;
}
textarea:focus, input:focus{
    outline: none;
}
input[type="file"]{
    border: none;
}
#replaced{
    background: linear-gradient(to bottom, #06363d,#06363d);
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0;
    text-transform: capitalize;
    border-top: 1px solid whitesmoke;
}
#form_div{
    width: 50%;
    margin: auto;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 0.5rem 1.5rem 0 rgb(0 0 0 / 10%);
    box-shadow: 0 0.5rem 1.5rem 0 rgb(0 0 0 / 40%);
    position: relative;
    z-index: 1;
}
#form_div form{
    padding: 20px 40px;
}
#form_div button{
    padding: 10px;
    width: 100%;
    color: white;
    background: #06363d;
    border: none;
    margin-bottom: 5%;
    border-radius: 5px;
}
@media only screen and (max-width: 768px) {
    #form_div form{
        padding: 10px 20px;
    }
    #form_div{
        padding: 0px;
        width: 100%;
        margin: auto;
    }
    .startDiv{
        padding: 0px 0;
    }
}