.flex-item {
    flex: 0 0 calc(33.33% - 10px); /* Adjust this calculation as needed */
    box-sizing: border-box; /* Ensure border and padding are included in the width */
}
@media only screen and (max-width: 998px){
  .flex-item {
    flex: 0 0 calc(49% - 10px); /* Adjust this calculation as needed */
    flex-grow: 1;
    margin-bottom: 10px;
    box-sizing: border-box; /* Ensure border and padding are included in the width */
  }
}