.image-slider {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    position: relative;
  }
  
  .slider-image {
    width: 100%;
    height: 40vh;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .slider-button {
    padding: 10px 20px;
    background-color: var(--blue);
    color: white;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .previous {
    left: 20px;
  }
  
  .next {
    right: 20px;
  }
  