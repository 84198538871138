.MessageForm form{
    padding: 0 10px 20px 10px;
}
.MessageForm label{
    display: block;
    font-size: 0.875rem;
}
.MessageForm input{
    width: 50%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 0.875rem;
    margin-bottom: 10px;
}
.editor-input{
    border: 1px solid #ddd;
    border: none;
}
.NoticeAdmin input{
    border: none;
    border: 1px solid grey;
}
.preview img{
    width: 100%;
}