.certificate h3{
    font-family: "Edwardian Script ITC";
    font-size: 4rem;
    text-align: center;
    font-weight: 500;
}
.certificate{
    width: 100%;
    margin: auto;
    height: 80vh;
    border: 20px solid transparent;
    padding: 15px;
    border-image: url('../images/border.png') 10 stretch;
    border-image: url('../images/border.png') 30 round;
    position: relative;
    top: 5vh;
    font-weight: 600;
}
.certificate::before{
    position: absolute;
    content: " ";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    background: url('../images/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.2;
}
.underlined,
.underlined2{
    border-bottom: 1px solid grey;
    color: rgb(231, 17, 17);
    font-size: 1.1rem;
}
.p{
    color: #06363d;
    font-size: 1.4rem;
}
.divided{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.divided div{
    width: 50%;
}
.divided img{
    width: 150px;
}
@media print {
    @page { size: landscape;size: A4 landscape; }
    .certificate{
        width: 80vw;
        margin: auto;
        height: 80vh;
        border: 20px solid transparent;
        padding: 15px;
        border-image: url('../images/border.png') 10 stretch;
        border-image: url('../images/border.png') 30 round;
        position: relative;
        top: 10vh;
        font-weight: 600;
    }
    .certificate::before{
        position: absolute;
        content: " ";
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
        background: url('../images/logo.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.2;
    }
    .certificate .download-heading{
        font-size: 1.2rem;
    }
}
@media only screen and (max-width: 998px){
    .download-heading{
        font-size: 1rem;
    }
    .certificate h3{
        font-family: "Edwardian Script ITC";
        font-size: 1.5rem;
        text-align: center;
        font-weight: 600;
    }
    .certificate{
        width: 100%;
        margin: auto;
        height: auto;
        border: 13px solid transparent;
        padding: 5px;
        border-image: url('../images/border.png') 10 stretch;
        border-image: url('../images/border.png') 30 round;
        position: relative;
        top: 2.5vh;
        font-weight: 600;
    }
    .certificate .p{
        color: #06363d;
    }
    .certificate p{
        margin: 0;
        font-size: 0.675rem;
    }
    .underlined,
    .underlined2{
        border-bottom: 1px solid grey;
        color: rgb(231, 17, 17);
        font-size: 0.875rem;
    }
    .divided{
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
    .divided div{
        width: 50%;
    }
    .divided img{
        width: 100px;
    }
}