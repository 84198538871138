.network-image {
    position: relative;
}
  
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
}
.network-image img{
    width: inherit;
    height: inherit;
}
  