.mainDiv{
    display: grid;
    grid-template-columns: 25% 75%;
    justify-items: center;
    /*align-items: center;*/
    position: relative;
    padding: 20px 2.5%;
    background-color: rgba(245, 245, 245, 0.8);
}
.mainDiv::before{
    position: absolute;
    content: " ";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: url("../images/fundraising_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
    z-index: -1;
}
.sideNavigation{
    width: 100%;
    height: 100vh;
    position: sticky;
    top: 18%;
}
.sideNavigation ul{
    padding: 0;
    margin: 0;
    width: inherit;
}
.sideNavigation ul li{
    list-style: none;
    padding: 10px 20px;
    transition: .4s;
    display: grid;
    grid-template-columns: 15% 85%;
    justify-content: center;
    align-items: center;
    background: grey;
    color: white;
    margin-bottom: 3px;
    cursor: pointer;
    width: 100%;
}

.sideNavigation ul li:hover{
    background-color: #67180b;
    cursor: pointer;
}


.menu {
    width: 0;
    overflow: hidden;
    transition: width 0.5s;
}
  
.menu.open {
    width: 95%;
}
.menu-button {
    padding: 10px 20px;
    background-color: #0074d9;
    color: #fff;
    border: none;
    cursor: pointer;
    display: none;
}
@media only screen and (max-width: 998px){
    .mainDiv{
        display: block;
        padding: 10px 0%;
    }
    .sideNavigation{
        position: absolute;
        top: 6%;
        z-index: 1;
        background: white;
        height: auto;
    }
    .menu-button{
        display: inline-block;
    }
}
  
  