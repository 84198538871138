h5 span{
    float: right;
    padding-right: 20px;
    font-weight: 700;
    cursor: pointer;
}
.share{
    background: white;
    padding: 20px;
}
.share h1{
    font-size: 1.5rem;
    font-weight: 800;
}
.share button{
    background-color:#06363d; 
    color: white;
    margin: 0;
    border: none;
    font-weight: 700;
    padding: 15px 20px;
    border-radius: 5px;
    width: 100%;
}
.shareIcons{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 60%;
    margin: auto;
}
.shareIcons .i{
    font-size: 35px;
    cursor: pointer;
}
legend{
    font-size: 15px;
}
.copy{
    display: grid;
    grid-template-columns: 1fr 30%;
    justify-content: center;
    align-items: center;
    grid-column-gap: 10px;
    margin: 20px 0;
}
.copy input{
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
}
@media only screen and (max-width: 998px) {
    .share h1{
        font-size: 1.2rem;
        font-weight: 800;
    }
    .shareIcons{
        width: 90%;
    }
    .shareIcons .i{
        font-size: 25px;
        cursor: pointer;
    }
    .copy{
        display: block;
    }
    .copy input{
        margin-bottom: 5px;
        border-radius: 5px;
        border: none;
        border: 1px solid grey;
    }
    h5 span{
        font-size: 18px;
    }
}