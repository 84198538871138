h5 span{
    float: right;
    padding-right: 20px;
    font-weight: 700;
    cursor: pointer;
}
.report{
    background: white;
    padding: 20px;
}
.report input, .report select, .report textarea{
    border: 1px solid rgb(212, 210, 210);
    margin-bottom: 10px;
    border-radius: 2px;
    font-size: 0.875rem;
    padding: 10px 5px;
    width: 100%;
}
.report input:focus, .report select:focus, .report textarea:focus{
    outline: none;
}
.report button{
    background-color:#06363d; 
    color: white;
    margin: 0;
    border: none;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 5px;
    width: 100%;
}