.Footer {
    background-color: #282828;;
    padding: 20px 5%;
    display: flex;
    justify-content: space-between;
    color: white;
    flex-wrap: wrap;
} 
.FooterSection {
    flex: 1;
    padding: 0 20px;
}
  
.Footer h3 {
    font-size: 20px;
    margin-bottom: 10px;
}
  
.Footer p {
    font-size: 14px;
    margin: 0;
}
.last{
    display: flex;
    background-color: rgb(26, 25, 25);
    color: white;
    justify-content: space-between;
    padding: 10px 5%;
}
.last div{
    width: 60%;
    display: flex;
    gap: 2%;
}
.last div p{
    flex: 1;
    flex-grow: 1;
}
@media only screen and (max-width: 998px){
    .Footer {
        display: block;
    } 
    .last{
        display: flex;
        width: 100%;
        overflow: auto;
    }
    .last div, 
    .last p{
        flex-shrink: 0;
        width: 60%;
    }
    .last div{
        width: auto;
        display: flex;
        gap: 2%;
    }
}
  
  