/* ... existing styles ... */

/* Basic progress bar styles */
.progress-bar {
    width: 100%;
    height: 15px;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .progress-bar-fill {
    height: 100%;
    border-radius: 10px;
    background-color: #3498db;
    transition: width 0.3s ease-in-out;
  }
  
  /* Custom progress bar styles for iOS */
  /* iOS uses a more native-looking progress bar style */
  @media screen and (max-device-width: 768px) {
    /* Target mobile devices, adjust the max-device-width value as needed */
    .progress-bar {
      height: 15px;
      background-color: transparent;
    }
  
    .progress-bar-fill {
      border-radius: 0;
      background-color: #3498db;
    }
  }


.loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #7c6c6c;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.hidden {
    display: none;
}
.card img{
    height: 230px;
    object-fit: contain;
    border-radius: 5px 5px 0 0;
}
.card-elements{
    display: flex;
    gap: 2%;
    padding: 0 5%;
    flex-wrap: wrap;
}
.card-elements .card{
    width: 32%;
    border-radius: 5px;
    margin-bottom: 2.5%;
}
.card figcaption{
    color: #0071DC;
    font-weight: 700;
}
.card h4{
    font-weight: 700;
    font-size: 1rem;
    padding: 5px 0;
}
.card .contents{
    flex-basis: Calc(100% - 10px);
    background-color: whitesmoke;
    cursor: pointer;
    padding: 10px;
    border-radius: 0 0 5px 5px;
    border: none;
}
@media only screen and (max-width: 998px){
    .card-elements .card{
        width: 100%;
        border-radius: 5px;
        margin-bottom: 2.5%;
    }
}