#paymentForm{
    text-align: left;
    background: white;
    padding: 5px 20px;
}
#paymentForm label{
    font-size: 0.875rem;
}
#paymentForm img{
    width: 30%;
}
#paymentForm figcaption{
    position: absolute;
    top: 0;
    left: 35%;
}
#paymentForm h4{
    position: absolute;
    bottom: 0;
    left: 35%;
    font-size: 1.2rem;
    font-weight: 700;
    color: #3d0d06;
}
#paymentForm button{
    background: #06363d;
    color: white;
}
#paymentForm textarea{
    width: 100%;
    margin-top: 10px;
}
h5 span{
    float: right;
    padding-right: 20px;
    font-weight: 700;
    cursor: pointer;
}
.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #003232;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04AA6D;
    cursor: pointer;
}