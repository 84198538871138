.Stats{
    background: whitesmoke;
    padding: 20px;
    justify-content: space-evenly;
}
.Stats div{
    background: white;
    padding: 5px 80px;
    border-radius: 10px 0;
    font-weight: 700;
}
@media only screen and (max-width: 998px){
    .Stats{
        overflow: auto;
        gap: 2%;
        justify-content: flex-start;
    }
    .Stats div{
        width: auto;
        padding: 5px 10px;
        flex-shrink: 0;
        font-size: 0.875rem;
    }
}