#volunteerForm{
    text-align: left;
    background: white;
    padding: 0 20px 20px 20px;
}
.divided{
    display: flex;
    gap: 20px;
}
.divided div{
    flex-grow: 1;
    text-align: left;
}
#volunteerForm button{
    background: #06363d;
    color: white;
    border: none;
    padding: 5px 15px;
}
#volunteerForm input,#volunteerForm select, #volunteerForm textarea{
    border: 1px solid rgb(212, 210, 210);
    margin-bottom: 3px;
    border-radius: 2px;
    font-size: 0.875rem;
    padding: 5px 5px;
    width: 100%;
}
#volunteerForm input:focus,#volunteerForm select:focus, #volunteerForm textarea:focus{
    outline: none;
}
#volunteerForm label{
    font-size: 0.875rem;
    font-weight: 700;
}
h5 span{
    float: right;
    padding-right: 20px;
    font-weight: 700;
    cursor: pointer;
}